import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { NotFoundPage } from './Common';
import { BrowserRouter } from 'react-router-dom';
import { Box, createTheme, styled, ThemeProvider } from '@mui/material';
import { ProvideAuth } from '../hooks';
import { LoginPaper } from './Login';
import { Planner } from './Planner';
import Schedule from './Schedule/Schedule';
import { LicenseInfo } from '@mui/x-license';
import { ProvideSnackBar } from '../hooks/useSnackBar';
import { StopCheckboxProvider } from '../hooks/useRequirementStopSelectionContext';
import Matrix from './Matrix/Matrix';
import SpeedLimits from './SpeedLimits/SpeedLimits';
import PlanList from './Plans/PlanList';
import { huHU } from '@mui/x-data-grid/locales';
import { RequirementStopPassengerDataProvider } from '../hooks/useRequirementStopPassengerCount';

const MUI_LICENSE_KEY = (window as any).REACT_APP_MUI_LICENSE_KEY || 'not known';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const StyledApp = styled(Box)`
    background-image: url('/images/br_logo_vertical.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    min-height: 100%;
    height: 100%;
`;

const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#42C4AB',
            },
            success: {
                main: '#42C4AB',
            },
        },
    },
    huHU
);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <StyledApp>
                <BrowserRouter>
                    <ProvideAuth>
                        <ProvideSnackBar>
                            <StopCheckboxProvider>
                                <RequirementStopPassengerDataProvider>
                                    <Routes>
                                        <Route path='/' element={<Navigate to={'/planner'} />} />
                                        <Route path='/schedule' element={<Schedule />} />
                                        <Route path='/planner' element={<Planner />} />
                                        <Route path='/plans' element={<PlanList />} />
                                        <Route path='/matrix' element={<Matrix />} />
                                        <Route path='/speed-limits' element={<SpeedLimits />} />
                                        <Route path='/login' element={<LoginPaper />} />
                                        <Route path='*' element={<NotFoundPage />} />
                                    </Routes>
                                </RequirementStopPassengerDataProvider>
                            </StopCheckboxProvider>
                        </ProvideSnackBar>
                    </ProvideAuth>
                </BrowserRouter>
            </StyledApp>
        </ThemeProvider>
    );
}

export default App;
