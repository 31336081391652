import React, { useEffect, useState } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Requirement, Stop } from '../../@types/requirements';
import { LineStop } from '../../@types/lines';
import { DragOverlay, useDraggable } from '@dnd-kit/core';
import { DragIndicator } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    DataGridPro,
    DataGridProProps,
    GRID_TREE_DATA_GROUPING_FIELD,
    GridColDef,
    GridFooterContainer,
    GridRenderCellParams,
    GridRowsProp,
    GridSlotsComponentsProps,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { RequirementItem } from './RequirementItem';
import PassengerCheckboxesCell from './PassengerCheckboxesCell';
import ErrorBoundary from '../../hooks/errorBoundary';
import StopListGridTreeDataGroupingCell from './StopListGridTreeDataGroupingCell';
import { useStopListExpandedGroupingRows } from '../../hooks/useStopListExpandedGroupingRows';
import { useRequirementStopPassengerDataContext } from '../../hooks/useRequirementStopPassengerCount';

type RequirementListProps = {
    requirements: Requirement[];
    stopsOnLines: LineStop[];
    stops: Stop[];
    activeId: string | undefined | null;
    showAllLinesOnMap: boolean;
    setShowAllLinesOnMap: (val: boolean) => void;
};

const DataGridFooter = (props: NonNullable<GridSlotsComponentsProps['footer']>) => {
    return (
        <GridFooterContainer>
            {props.summary && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: 1,
                        padding: 1,
                    }}>
                    <Typography variant='h6'>Összegzés</Typography>
                    <Box sx={{ display: 'flex' }}>
                        {Object.keys(props.summary).map((key, index) => (
                            <Typography key={index} variant='body2' px={1.25}>
                                {props.summary ? props.summary[key] : 0}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            )}
        </GridFooterContainer>
    );
};

const RequirementList = ({ requirements, stopsOnLines, stops, activeId, showAllLinesOnMap, setShowAllLinesOnMap }: RequirementListProps) => {
    const [rows, setRows] = useState<GridRowsProp>([]);
    const { expandedRows } = useStopListExpandedGroupingRows();
    const { getStopNPassengers, isTravelPassenger } = useRequirementStopPassengerDataContext();
    const passengersOnLines = stopsOnLines.flatMap(stop => stop.Passengers);
    const apiRef = useGridApiRef();
    const [summary, setSummary] = useState<{ [key: string]: any }>({});

    const createRowsFromStops = (stops: Stop[], requirements: Requirement[]): GridRowsProp => {
        let rows: GridRowsProp = [];
        stops.forEach(stop => {
            const stopData: { [key: string]: any } = {
                id: stop.CrmID,
                path: stop.Name,
                name: stop.Name,
                type: 'STOP',
                stop: stop,
            };

            for (const r of requirements) {
                stopData['requirement-' + r.DueAt] = 0;

                const requirementStop = r.Stops.find(s => s.CrmID === stop.CrmID);
                if (requirementStop) {
                    stopData['requirement-' + r.DueAt] = getStopNPassengers(r.ID, stop.CrmID);
                    for (const p of requirementStop.Passengers) {
                        const passenger = rows.find(row => row.id === p.WorkerId + '_' + stop.ID);
                        if (passenger) {
                            passenger['isRequirementPassenger' + r.DueAt] = true;
                            passenger['rId' + r.DueAt] = p.ID;
                            passenger['rTravel' + r.DueAt] = isTravelPassenger(r.ID, p.WorkerId);
                            continue;
                        }
                        if (passengersOnLines.find(passenger => passenger.WorkerID === p.WorkerId && passenger.StopCrmID === stop.CrmID) !== undefined) {
                            continue;
                        }
                        const passengerData: { [key: string]: any } = {
                            id: p.WorkerId + '_' + stop.ID,
                            path: stop.Name + '//' + p.Name + ' ' + p.WorkerId + ' ' + stop.ID,
                            type: 'PASSENGER',
                            name: p.Name,
                            stopId: stop.CrmID,
                        };
                        passengerData['isRequirementPassenger' + r.DueAt] = true;
                        passengerData['rId' + r.DueAt] = p.ID;
                        passengerData['rTravel' + r.DueAt] = isTravelPassenger(r.ID, p.WorkerId);
                        requirements.forEach(r => (passengerData['requirement-' + r.DueAt] = 0));
                        rows = rows.concat(passengerData);
                    }
                }
            }
            rows = rows.concat(stopData);
        });
        return rows;
    };

    const createSummaryRow = (requirements: Requirement[], stopsOnLines: LineStop[]) => {
        const summary: { [key: string]: any } = {};
        requirements.forEach(r => {
            summary['requirement-' + r.DueAt] = r.Stops.filter(
                stop => !stopsOnLines.some(stopOnLine => stopOnLine.CrmID === stop.CrmID)
            ).reduce((sum, stop) => { 
                return sum + getStopNPassengers(r.ID, stop.CrmID);
            }, 0);
        });
        setSummary(summary);
    };

    const updateDatagridRows = (rowId: number, dueAt: string, stopId: number, passengerTravel: boolean) => {
        const updateStopData: { [key: string]: any } = {
            id: stopId,
        };
        const currentSum = apiRef.current.getRow(stopId)['requirement-' + dueAt];
        updateStopData['requirement-' + dueAt] = passengerTravel ? currentSum + 1 : currentSum - 1;
        apiRef.current.updateRows([updateStopData]);
        setSummary(prevState => {
            const newSummary = { ...prevState };
            newSummary['requirement-' + dueAt] = passengerTravel ? prevState['requirement-' + dueAt] + 1 : prevState['requirement-' + dueAt] - 1;
            return newSummary;
        });
    };

    useEffect(() => {
        const rows = createRowsFromStops(stops, requirements);
        createSummaryRow(requirements, stopsOnLines);
        setRows([...rows]);
    }, [requirements, stops, stopsOnLines]);

    const columns: GridColDef[] = [
        {
            field: 'requirementItem',
            headerName: ' ',
            width: 75,
            flex: 0.1,
            disableColumnMenu: true,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'requirement-item-cell',
            display: 'flex',
            renderHeader: () => (
                <Tooltip title={showAllLinesOnMap ? 'Összes megálló elrejtése a térképről' : 'Összes megálló megtekintése a térképen'}>
                    <IconButton size='small' sx={{ marginLeft: 0.6 }} onClick={() => setShowAllLinesOnMap(!showAllLinesOnMap)}>
                        {showAllLinesOnMap ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                </Tooltip>
            ),
            renderCell: params => {
                if (params.row.type !== 'STOP') {
                    return <></>;
                }
                return <RequirementItem stop={params.row.stop} />;
            },
        },
        {
            field: GRID_TREE_DATA_GROUPING_FIELD,
        },
        {
            field: 'name',
            headerName: ' ',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            headerAlign: 'left',
            align: 'left',
            display: 'flex',
            renderCell: params => {
                return <RequirementPassengerRow params={params} />;
            },
        },
    ];

    requirements.forEach(r => {
        const dueAt = new Date(Date.parse(r.DueAt));
        columns.push({
            field: 'requirement-' + r.DueAt,
            headerName: `${dueAt.toLocaleDateString('hu-HU', { day: '2-digit' })} ${dueAt.toLocaleDateString('hu-HU', { weekday: 'short' })}`,
            width: 40,
            minWidth: 40,
            maxWidth: 40,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'requirement-header',
            display: 'flex',
            renderCell: params => {
                if (params.row.type === 'PASSENGER') {
                    if (!params.row['isRequirementPassenger' + r.DueAt]) {
                        return <span style={{ margin: '0 2px' }}>-</span>;
                    }
                    return (
                        <PassengerCheckboxesCell
                            dueAt={r.DueAt}
                            rowId={params.row.id}
                            stopId={params.row.stopId}
                            stopCrmId={params.row.stopId}
                            requirementId={r.ID}
                            passengerId={params.row['rId' + r.DueAt]}
                            passengerTravel={params.row['rTravel' + r.DueAt]}
                            updateDataGridRows={updateDatagridRows}
                        />
                    );
                }
            },
        });
    });

    const groupingColDef: DataGridProProps['groupingColDef'] = {
        headerName: ' ',
        width: 20,
        minWidth: 20,
        maxWidth: 20,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        resizable: false,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: params => <StopListGridTreeDataGroupingCell {...params} />,
    };

    return (
        <Stack
            sx={{
                minHeight: '65vh',
                height: '100%',
                width: '100%',
            }}>
            <ErrorBoundary>
                <DataGridPro
                    treeData
                    apiRef={apiRef}
                    getTreeDataPath={row => row.path.split('//')}
                    groupingColDef={groupingColDef}
                    rows={rows}
                    columns={columns}
                    rowHeight={30}
                    isGroupExpandedByDefault={params => {
                        return params.groupingKey ? expandedRows.hasOwnProperty(params.groupingKey.toString()) : false;
                    }}
                    slots={{
                        footer: DataGridFooter,
                    }}
                    slotProps={{
                        footer: {
                            summary: summary,
                        },
                    }}
                    sx={{
                        '& .requirement-header': {
                            width: '5px',
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: 'wrap',
                                lineHeight: 'initial',
                                textAlign: 'center',
                            },
                        },
                        '& .MuiDataGrid-cell': {
                            padding: 0,
                        },
                        '& .requirement-item-cell': {
                            padding: 0,
                        },
                    }}
                />
            </ErrorBoundary>
            <DragOverlay dropAnimation={null}>{activeId ? <Typography sx={{ width: 400 }}>{activeId}</Typography> : null}</DragOverlay>
        </Stack>
    );
};

function RequirementPassengerRow({ params }: { params: GridRenderCellParams }) {
    const dragData = {
        id: params.row.id,
        data: {
            stop: params.row.stop,
            passengerWorkerId: params.row.id,
            passengerName: params.row.name,
        },
    };

    const { attributes, listeners, setNodeRef } = useDraggable(dragData);

    if (params.row.type !== 'STOP') {
        return (
            <>
                <Box ref={setNodeRef} {...attributes} id={params.row.id} sx={{ display: 'flex', alignItems: 'center' }}>
                    <DragIndicator
                        {...listeners}
                        sx={{
                            verticalAlign: 'middle',
                            cursor: 'grab',
                        }}
                    />
                    <Typography>{params.row.name}</Typography>
                </Box>
            </>
        );
    }

    return (
        <Typography
            m={0}
            p={0}
            paragraph
            sx={{
                display: 'inline-block',
                verticalAlign: 'middle',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                maxWidth: '95%',
                backgroundColor: !params.row.stop.AllPassengerHeadquarter ? 'yellow' : '',
            }}
            title={params.row.stop.Name}>
            {params.row.stop.Name}
        </Typography>
    );
}

export { RequirementList };
