import { createContext, useContext, useState } from 'react';
import { RequirementStopData, RequirementStopPassengerContextType, RequirementStopPassengerData } from '../@types/requirements';

const RequirementStopPassengerDataContext = createContext<RequirementStopPassengerContextType>({
    requirementStopPassengerData: [],
    addRequirementStopPassengerData: (rspd: RequirementStopPassengerData) => {},
    changeNPassengers: (requirementID: number, crmID: number, passengerID: number, isTravel: boolean) => {},
    getStopNPassengers: (requirementID: number, crmID: number) => 0,
    isTravelPassenger: (requirementID: number, passengerID: number) => false,
    calculatePassengers: (requirementID: number) => [],
});

export const useRequirementStopPassengerDataContext = () => useContext(RequirementStopPassengerDataContext);

export const RequirementStopPassengerDataProvider = ({ children }: { children: React.ReactNode }) => {
    const [requirementStopPassengerData, setRequirementStopPassengerData] = useState<RequirementStopPassengerData>({});

    const addRequirementStopPassengerData = (rspd: RequirementStopPassengerData) => {
        setRequirementStopPassengerData({
            ...requirementStopPassengerData,
            ...rspd,
        });
    };

    const changeNPassengers = (requirementID: number, crmID: number, passengerID: number, isTravel: boolean) => {
        setRequirementStopPassengerData({
            ...requirementStopPassengerData,
            [requirementID]: {
                "stops": {
                    ...requirementStopPassengerData[requirementID]?.stops,
                    [crmID]: (requirementStopPassengerData[requirementID]?.stops[crmID] ?? 0) + (isTravel ? 1 : -1),
                },
                "passengers": {
                    ...requirementStopPassengerData[requirementID]?.passengers,
                    [passengerID]: isTravel,
                },
            },
        });
    };

    const getStopNPassengers = (requirementID: number, crmID: number) => {
        return requirementStopPassengerData[requirementID]["stops"][crmID];
    }

    const isTravelPassenger = (requirementID: number, passengerID: number) => {
        return requirementStopPassengerData[requirementID]["passengers"][passengerID];
    };

    const calculatePassengers = (requirementID: number) => {
        const stops = requirementStopPassengerData[requirementID].stops;
        return Object.entries(stops).map(([CrmID, NPassengers]) => {
            return {
                CrmID: parseInt(CrmID),
                NPassengers,
            };
        });
    };

    return (
        <RequirementStopPassengerDataContext.Provider
            value={{
                requirementStopPassengerData,
                addRequirementStopPassengerData,
                changeNPassengers,
                getStopNPassengers,
                isTravelPassenger,
                calculatePassengers,
            }}>
            {children}
        </RequirementStopPassengerDataContext.Provider>
    );
};
