import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import React, { useEffect, useState } from 'react';
import { SecuredLayout } from '../Layout/SecuredLayout';
import huLocale from 'date-fns/locale/hu';
import {getEnumKeyByValue, useAuth, useProvideSnackBar} from '../../utils';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import NewLine from './NewLine';
import { PlannerMap } from './PlannerMap';
import { LineIsOnMapState, LineRoute, LineTravelDistance, Stop as MapStop } from '../../@types/map';
import { DirectionType, Requirement, RequirementFilterParams, RequirementStopPassengerData, Stop } from '../../@types/requirements';
import { Line, LinesResponse, LineStop, LineStopItem } from '../../@types/lines';
import { RequirementSelectForm } from './RequirementSelectForm';
import { LineInformation } from './LineInformation';
import { DndContext, DragStartEvent } from '@dnd-kit/core';
import { RequirementList } from './RequirementList';
import DeleteLineModal from './DeleteLineModal';
import { Role } from '../../@types/auth';
import { useRequirementStopSelectionContext } from '../../hooks/useRequirementStopSelectionContext';
import { StopListExpandedGroupingRowsProvider } from '../../hooks/useStopListExpandedGroupingRows';
import { useRequirementStopPassengerDataContext } from '../../hooks/useRequirementStopPassengerCount';

type LineRouteRequestData = {
    ID: number;
    Name: string;
};

export default function Planner() {
    const { showError, showResponseError } = useProvideSnackBar();
    const { user, signOut } = useAuth();
    const { requirementStopPassengerData, addRequirementStopPassengerData } = useRequirementStopPassengerDataContext();
    const [activeId, setActiveId] = useState<string | null>(null);

    const [requirements, setRequirements] = useState<Array<Requirement>>([]);
    const [selectedMapRequirementID, setSelectedMapRequirementID] = useState<number>(0);

    const [lines, setLines] = useState<Array<Line>>([]);
    const [lineMap, setLineMap] = useState<Map<string, Line[]>>(new Map());
    const [stopsOnLines, setStopsOnLines] = useState<LineStop[]>([]);

    const [mapStopParams, setMapStopParams] = useState<Array<MapStop>>([]);
    const [stopsWithPassengers, setStopsWithPassengers] = useState<Stop[]>([]);

    const { stopSelectionStatus, setStopSelectionStatus } = useRequirementStopSelectionContext();
    const [linesForMap, setLinesForMap] = useState<LineIsOnMapState>({});
    const [stops, setStops] = useState<Stop[]>([]);

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [linesToDelete, setLinesToDelete] = useState<Line[]>([]);
    const [filterParams, setFilterParams] = useState<RequirementFilterParams | undefined>(undefined);

    const [routes, setRoutes] = useState<LineRoute>({ Routes: [] });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [progressValue, setProgressValue] = useState(0);
    const [onSynced, setOnSynced] = useState<boolean>(false);
    const [mapExpanded, setMapExpanded] = useState(false);
    const [showRoutes, setShowRoutes] = useState(false);
    const [direction, setDirection] = useState<keyof typeof DirectionType>('FROM_HOME' as keyof typeof DirectionType);
    const [isScheduleEditing, setIsScheduleEditing] = useState<boolean>(false);
    const [showAllLinesOnMap, setShowAllLinesOnMap] = useState<boolean>(true);
    const [hasUnsyncedData, setHasUnsyncedData] = useState<boolean>(false);
    const [hasCalculatedLineTravelDistance, setHasCalculatedLineTravelDistance] = useState<boolean>(false);

    const fetchRequirements = async () => {
        if (!filterParams || !filterParams.planID) {
            return;
        }

        const params = {
            planID: filterParams.planID,
        };

        const response = await fetch('/api/requirements/search?' + new URLSearchParams(params).toString(), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
        });

        if (response.status === 401) {
            signOut();
        }

        if (!response.ok) {
            await showResponseError(response);
            return;
        }
        const body: Requirement[] = await response.json();
        setRequirements(body);
        setSelectedMapRequirementID(body[0].ID);
        addRequirementStopPassengerData(body.reduce((acc: RequirementStopPassengerData, requirement: Requirement) => {
            acc[requirement.ID] = {
                stops: requirement.Stops.reduce((acc, stop) => {
                    acc[stop.CrmID] = stop.NPassengers;
                    return acc;
                }, {} as { [key: number]: number }),
                passengers: requirement.Stops.reduce((acc, stop) => {
                    stop.Passengers.forEach(p => {
                        acc[p.WorkerId] = p.Travels;
                    });
                    return acc;
                }, {} as { [key: number]: boolean }),
            };
            return acc;
        }, {}));
    };

    useEffect(() => {
        const stopsOnLines = lines.flatMap(l => l.Stops);
        const passengersOnLines = stopsOnLines.flatMap(item => item.Passengers);
        setStops(
            requirements
                .flatMap(r => r.Stops)
                .reduce((stopArray: Stop[], stop: Stop) => {
                    const notInList = stopArray.find(s => s.Name === stop.Name) === undefined;
                    let notOnLine = false;

                    for (const passenger of stop.Passengers) {
                        if (passengersOnLines.find(item => item.WorkerID === passenger.WorkerId && item.StopCrmID === stop.CrmID) === undefined) {
                            notOnLine = true;
                            break;
                        }
                    }

                    if (notOnLine && notInList) {
                        stopArray.push(stop);
                    }
                    return stopArray;
                }, [])
                .sort((a: Stop, b: Stop) => a.Name.localeCompare(b.Name))
        );
        setStopsOnLines(stopsOnLines);
    }, [requirements, lines]);

    const fetchLines = async () => {
        try {
            const response = await fetch('/api/lines/search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    RequirementID: requirements.map(item => item.ID),
                }),
            });

            if (response.status === 401) {
                signOut();
            }

            if (!response.ok) {
                await showResponseError(response);
                return;
            }

            const body: LinesResponse = await response.json();

            const lineMap = new Map<string, Line[]>();
            const initLinesForMap: LineIsOnMapState = {};

            body.Lines.forEach(l => {
                const lineArray = lineMap.get(l.Name) || [];
                lineArray.push(l);
                lineMap.set(l.Name, lineArray);
                initLinesForMap[l.Name] = true;
            });

            setLineMap(lineMap);
            setLines(
                body.Lines.map(line => {
                    line.StopDistances = body.StopDistances[line.Name];
                    return line;
                })
            );
            setLinesForMap(initLinesForMap);
        } catch (e: any) {
            showError(`Hiba a járat lekérdezések során: ${e.message}`);
        }
    };

    useEffect(() => {
        if (filterParams) fetchRequirements();
    }, [filterParams]);

    useEffect(() => {
        fetchLines();
    }, [requirements]);

    useEffect(() => {
        if (showAllLinesOnMap) {
            const s = stopsWithPassengers.map(item => {
                return {
                    id: item?.ID,
                    name: item?.Name || '',
                    crmId: item?.CrmID || 0,
                    lat: item?.Latitude || 0,
                    lon: item?.Longitude || 0,
                };
            });
            setMapStopParams(s);
        } else {
            setMapStopParams([]);
        }
    }, [requirements, stopSelectionStatus, showAllLinesOnMap, stops, stopsWithPassengers]);

    const createLineStop = async (lineName: string, stop: Stop) => {
        const data: LineStopItem[] = [];

        const selectedStopIds = Object.keys(stopSelectionStatus).filter(stopId => stopSelectionStatus[parseInt(stopId)]);

        const selectedStops: Stop[] = selectedStopIds.length === 0 ? [stop] : stops.filter(stop => selectedStopIds.includes(stop.ID.toString()));

        const lineList = lines.filter((item: any) => item.Name === lineName);

        lineList.forEach((line: any) => {
            selectedStops.forEach(selectedStop => {
                const lineStopItem = {
                    LineID: line.ID,
                    CrmID: selectedStop.CrmID,
                    Name: selectedStop.Name,
                    Lat: selectedStop.Latitude,
                    Lon: selectedStop.Longitude,
                    RequirementStopID: requirements.find((r: Requirement) => r.ID === line.RequirementID)?.Stops.find((s: Stop) => s.Name === selectedStop.Name)
                        ?.ID,
                };
                data.push(lineStopItem);
                stopSelectionStatus[selectedStop.ID] = false;
            });
        });
        setStopSelectionStatus(stopSelectionStatus);
        const response = await fetch('/api/linestop', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                Stops: data,
            }),
        });
        if (!response.ok) {
            await showResponseError(response);
            return;
        }
        fetchLines();
        setHasUnsyncedData(direction === getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME));
    };

    const createLineStopPassenger = async (lineName: string, passengerWorkerId: number) => {
        const body = {
            LineIDs: lines.filter(line => line.Name === lineName).map(line => line.ID),
            PassengerWorkerID: passengerWorkerId,
        };

        const response = await fetch('/api/linestop/passenger', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            await showResponseError(response);
            return;
        }

        fetchLines();
    };

    const handleDeleteLine = async () => {
        if (linesToDelete === undefined) {
            return;
        }
        const response = await fetch(`/api/lines`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                IDs: linesToDelete.map(l => l.ID),
            }),
        });
        if (!response.ok) {
            const errorMessage = await response.text();
            showError(`Hiba történt a járat törlésekor: ${errorMessage}`);
            return;
        }
        setLinesToDelete([]);
        setModalOpen(false);
        fetchLines();
        setHasUnsyncedData(direction === getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME));
    };

    const handleDragStart = (e: DragStartEvent) => {
        if (e.active.data.current?.stop?.Name) {
            setActiveId(e.active.data.current?.stop.Name);
        }
        if (e.active.data.current?.passengerWorkerId) {
            setActiveId(e.active.data.current?.passengerName);
        }
    };

    useEffect(() => {
        setStopsWithPassengers(
            stops.filter(stop => {
                let hasPassenger = false;
                for (const r of requirements) {
                    const requirementStop = r.Stops.find(s => s.Name === stop.Name);
                    if ((requirementStop?.Passengers?.length || 0) > 0) {
                        hasPassenger = true;
                        break;
                    }
                }
                return hasPassenger;
            })
        );
    }, [requirements, stops]);

    const getRouteData = async (l: LineRouteRequestData[]) => {
        if (isLoading) return;
        setIsLoading(true);
        const response = await fetch('/api/lines/route', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                Lines: l.map(ln => {
                    return { ID: ln.ID, Name: ln.Name };
                }),
            }),
        });
        if (!response.ok) {
            return;
        }
        const body = await response.json();
        setRoutes(body);
        setIsLoading(false);
        setHasUnsyncedData(direction === getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME));
    };

    useEffect(() => {
        if (showRoutes) {
            getRouteData(
                lines
                    .filter(L => L.RequirementID === selectedMapRequirementID)
                    .map(L => {
                        return { ID: L.ID, Name: L.Name };
                    })
            );
        } else {
            setRoutes({ Routes: [] });
        }
    }, [lines, showRoutes, requirementStopPassengerData]);

    useEffect(() => {
        if (!isLoading) {
            setProgressValue(0);
            return;
        }
        const timeout = setTimeout(() => {
            const value = (100 - progressValue) / 3;
            setProgressValue(progressValue + value);
        }, 100);
        return () => clearTimeout(timeout);
    }, [isLoading]);

    useEffect(() => {
        const updatedLineTravelDistance: LineTravelDistance = {};

        Array.from(lineMap.values()).forEach((lineItem: Line[]) => {
            requirements.forEach(r => {
                const line = lineItem.find(l => l.RequirementID === r.ID);
                if (line) {
                    const hasCalculatedTravelDistance = Math.round(line.TravelDistance) > 0;
                    if (updatedLineTravelDistance.hasOwnProperty(line.Name)) {
                        updatedLineTravelDistance[line.Name] = updatedLineTravelDistance[line.Name] || hasCalculatedTravelDistance;
                        return;
                    }
                    updatedLineTravelDistance[line.Name] = hasCalculatedTravelDistance;
                }
            });
        });
        setHasCalculatedLineTravelDistance(Object.values(updatedLineTravelDistance).every(value => value));
    }, [lineMap]);

    if (user?.roles.includes(Role.DOLGOZO_HR) || user?.roles.includes(Role.DOLGOZO_MUSZAKVEZ)) {
        return null;
    }

    return (
        <SecuredLayout>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={huLocale}>
                <StopListExpandedGroupingRowsProvider>
                    <DndContext
                        onDragStart={handleDragStart}
                        onDragEnd={e => {
                            const { active, over } = e;

                            const stop = active.data.current?.stop;
                            const lineName = over?.data.current?.lineName;

                            if (stop && lineName) {
                                createLineStop(lineName, stop);
                            }

                            const workerIdStopId = active.data.current?.passengerWorkerId;
                            if (!workerIdStopId) {
                                return;
                            }
                            const ids = workerIdStopId.split('_');
                            if (!ids.length) {
                                return;
                            }

                            if (active.data.current?.passengerWorkerId && lineName) {
                                createLineStopPassenger(lineName, Number(ids[0]));
                            }
                        }}>
                        <RequirementSelectForm
                            onSubmit={(params: RequirementFilterParams) => setFilterParams(params)}
                            onReset={() => setRequirements([])}
                            onFormChange={() => fetchLines()}
                            onPlanUpdate={(hasNewUnsyncedData: boolean) => {
                                setHasUnsyncedData(hasNewUnsyncedData && direction === getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME));
                            }}
                            setOnSynced={isSynced => setOnSynced(isSynced)}
                            direction={direction}
                            setDirection={setDirection}
                            isScheduleEditing={isScheduleEditing}
                            setIsScheduleEditing={setIsScheduleEditing}
                            hasUnsyncedData={hasUnsyncedData}
                            hasCalculatedLineTravelDistance={hasCalculatedLineTravelDistance}
                        />

                        {filterParams !== undefined && requirements.length > 0 && !isScheduleEditing ? (
                            <Grid container p={1} pb={2} spacing={1} className={'planner-data-holder'}>
                                <Grid item xs={12} px={1} pb={progressValue > 0 ? 0 : 0.5}>
                                    {progressValue > 0 ? <LinearProgress variant={'indeterminate'} value={progressValue} /> : null}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    xl={4}
                                    alignItems={'right'}
                                    justifyContent={'right'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    className={'planner-data-column'}>
                                    <Box sx={{ overflow: 'auto', flexGrow: 1, marginBottom: 1.5 }}>
                                        {Array.from(lineMap.values()).map((lineItem: Line[]) => (
                                            <LineInformation
                                                key={lineItem[0].ID}
                                                requirements={requirements}
                                                lines={lineItem}
                                                onDelete={(lines: Line[]) => {
                                                    setLinesToDelete(lines);
                                                    setModalOpen(true);
                                                }}
                                                afterChangeCallback={() => {
                                                    fetchLines();
                                                    setHasUnsyncedData(direction === getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME));
                                                }}
                                                linesForMap={linesForMap}
                                                setLinesForMap={setLinesForMap}
                                                workplace={filterParams.workplace}
                                                allLines={lines}
                                                onSynced={onSynced}
                                                direction={direction}
                                            />
                                        ))}
                                    </Box>
                                    <NewLine requirements={requirements} afterCreate={() => fetchLines()} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    xl={4}
                                    sx={{ display: mapExpanded || stopsWithPassengers.length === 0 ? 'none' : 'block' }}
                                    alignItems={stopsWithPassengers.length > 0 ? 'flex-start' : 'center'}
                                    justifyContent={'center'}
                                    display={'flex'}
                                    className={'planner-data-column'}>
                                    {stopsWithPassengers.length > 0 ? (
                                        <RequirementList
                                            requirements={requirements}
                                            stopsOnLines={stopsOnLines}
                                            stops={stopsWithPassengers}
                                            activeId={activeId}
                                            showAllLinesOnMap={showAllLinesOnMap}
                                            setShowAllLinesOnMap={setShowAllLinesOnMap}
                                        />
                                    ) : (
                                        <Typography align={'center'}>Nincs több megálló</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={6} xl={mapExpanded || stopsWithPassengers.length === 0 ? 8 : 4} className={'planner-data-column'}>
                                    <PlannerMap
                                        requirements={requirements}
                                        selectedRequirementID={selectedMapRequirementID}
                                        onSelectRequirement={(checked, requirement) => {
                                            if (!checked) {
                                                setSelectedMapRequirementID(requirements[0].ID);
                                                return;
                                            }
                                            setSelectedMapRequirementID(requirement.ID);
                                        }}
                                        stops={mapStopParams}
                                        lines={lines.filter(line => linesForMap[line.Name])}
                                        allLines={lines}
                                        factory={{
                                            name: filterParams?.workplace?.Name,
                                            lat: filterParams?.workplace?.Latitude,
                                            lon: filterParams?.workplace?.Longitude,
                                        }}
                                        routes={routes}
                                        showRoutes={showRoutes}
                                        setShowRoutes={setShowRoutes}
                                        mapExpanded={mapExpanded || stopsWithPassengers.length === 0}
                                        setMapExpanded={setMapExpanded}
                                    />
                                </Grid>
                                <DeleteLineModal
                                    line={linesToDelete[0]}
                                    open={modalOpen}
                                    onClose={() => {
                                        setModalOpen(false);
                                        setLinesToDelete([]);
                                    }}
                                    onDelete={handleDeleteLine}
                                />
                            </Grid>
                        ) : null}
                    </DndContext>
                </StopListExpandedGroupingRowsProvider>
            </LocalizationProvider>
        </SecuredLayout>
    );
}
